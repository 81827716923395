

.main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.card {
  transition: transform 0.2s, box-shadow 0.2s;
  border-radius: 10px;
}

.card:hover {
  transform: scale(1.03);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.15);
}

.card-title {
  font-size: 1.5rem !important;
  font-weight: bold !important;
}

.card-header {
  background: linear-gradient(135deg, #d4e2f2 0%, #b8c9e4 100%);
  border: none !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

h1.text-primary {
  background: linear-gradient(135deg, #d4e2f2 0%, #a6c0fe 100%);
  padding: 20px;
  border-radius: 10px;
  font-size: 2.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

p.text-center {
  font-size: 1.1rem;
  color: #555;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
}
